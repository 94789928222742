import React, { useState } from "react";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h2 id="accordion-collapse-heading">
        <div
          className="flex items-center justify-between w-full px-10 py-5 font-medium text-left hover:bg-cyan-500 text-slate-100 border border-slate-700 rounded focus:ring-4 focus:ring-gray-200 my-6"
          onClick={() => setIsActive(!isActive)}
        >
          <span>{title}</span>
          <div>{isActive ? 
                <svg class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                  </svg> 
                  : 
                  <svg class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                </svg>}</div>

        </div>
      </h2>
      {isActive && (
        <div id="accordion-collapse-body ">
          <div className=" m-auto rounded-md border-gray-500 ">
            <div className="mb-2 text-slate-600 bg-slate-50 rounded p-8">
              <p>{content}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
