import { StarIcon, HomeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import section1IMG from '../../imgs/WhatWeDo/section1IMG.jpg';
import section2IMG from "../../imgs/WhatWeDo/section2IMG.jpg";
import charterDP from '../../imgs/WhatWeDo/badge1.png'
import nrlaDP from '../../imgs/WhatWeDo/badge2.png'
import twtaDP from '../../imgs/WhatWeDo/badge3.png'

import { WhatWeDoContent } from "../../Content/AboutUs/WhatWeDoContent";
const WhatWeDo = () => {
  return (
    <div className="mb-4">
            <div class="justify-center items-center flex-col mt-2 lg:mt-8 min-[2000px]:-mt-2 ">
        <div class="mb-6 md:mb-8 w-full h-full bg-whatWeDoBanner bg-cover bg-center bg-fixed ">
          <div
            class="w-full h-full flex justify-center items-center backdrop-brightness-50 backdrop-blur-[2px]">
            <div className="mx-auto place-self-center lg:col-span-7 p-4 my-auto text-center">
              <h1 className=" py-52 lg:py-60 max-w-2xl text-7xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
                What We Do
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div>


      <div className="relative isolate overflow-hidden bg-whitepy-10 sm:py-24 lg:overflow-visible lg:px-0 py-8 ">
        <div className="absolute inset-0 -z-10 overflow-hidden">
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-14 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-14">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4 px-4 lg:px-0">
              <div className="lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-cyan-500">
                  {WhatWeDoContent.section1MiniTitle}
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {WhatWeDoContent.section1Title}
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  {WhatWeDoContent.section1Subheading}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:-ml-12 lg:-mt-12 lg:p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className=" md:rounded-xl lg:max-w-2xl mx-auto w-[48rem] max-w-screen bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              src={section1IMG}
              alt=""
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8  lg:px-8">
            <div className="lg:pr-4 px-4 lg:px-0">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <p>
                  {WhatWeDoContent.section1TextBlockA}
                </p>
                <ul className="mt-8 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                    <StarIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-500"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        {WhatWeDoContent.section1TextBlockBHeading}
                      </strong>
                      <br></br> {WhatWeDoContent.section1TextBlockB}
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <HomeIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-500"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        {WhatWeDoContent.section1TextBlockCHeading}
                      </strong>
                      <br></br>{WhatWeDoContent.section1TextBlockC}
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <PhoneIcon
                      className="mt-1 h-5 w-5 flex-none text-cyan-500"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        {WhatWeDoContent.section1TextBlockDHeading}
                      </strong>
                      <br></br> {WhatWeDoContent.section1TextBlockD}
                    </span>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-cyan-600">
      <div className="xl:px-20 px-5 py-6 lg:py-12 2xl:mx-auto 2xl:container relative z-40">
          <h1 className="text-4xl md:text-5xl font-bold xl:block leading-tight text-white mb-5 lg:mb-20 text-center">
          {WhatWeDoContent.section2Title}
          </h1>
              <div className="flex">
                <div className="md:flex">
                  <div className="relative sm:w-96 xl:h-96 h-80">
                    <img
                      src={section2IMG}
                      alt="Profile"
                      className="w-full h-full flex-shrink-0 object-fit object-cover shadow-lg rounded-md"
                    />
                    <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-sky-100 rounded-full">
                        <svg width="42" height="42" viewBox="0 0 42 42" className="fill-cyan-700" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 19.7072H10.1869C10.0128 27.1315 7.90703 27.9742 5.2623 28.2148L4.24242 28.3412V36.5049L5.418 36.4419C8.87154 36.2476 12.6897 35.625 15.2371 32.4803C17.4701 29.7236 18.4545 25.2198 18.4545 18.3062V5.49512H0V19.7072Z" />
                        <path d="M23.5459 5.49512V19.7072H33.5968C33.4227 27.1315 31.3851 27.9742 28.7403 28.2148L27.7883 28.3412V36.5049L28.896 36.4419C32.3496 36.2476 36.2019 35.625 38.7493 32.4803C40.982 29.7236 42.0004 25.2198 42.0004 18.3062V5.49512H23.5459Z"  />
                      </svg>
                    </div>
                  </div>
                  <div className="md:w-1/3 lg:w-3/4 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                    <div>
                      <h1 className="text-3xl font-semibold xl:leading-loose text-white">
                      {WhatWeDoContent.section2Heading}
                      </h1>
                      <p className="text-base leading-6 rounded p-4 my-4 bg-slate-50 text-slate-700">
                      {WhatWeDoContent.section2TextBlockA}</p>
                      <p className="text-base leading-6 rounded p-4 my-4 bg-slate-50 text-slate-700">
                      {WhatWeDoContent.section2TextBlockB} </p>
                    </div>
                    <div className="md:mt-0 mt-8">
                      <p className="text-base font-medium leading-4 text-slate-100">
                      {WhatWeDoContent.section2Name}
                      </p>
                      <p className="text-base leading-4 mt-3 mb-2 text-slate-100">
                      {WhatWeDoContent.section2JobTitle}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
           
      </div>
      <section class="bg-white">
    <div class="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
        <h2 class="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-cyan-600  md:text-4xl">{WhatWeDoContent.section3Title}</h2>
        <div class="grid grid-cols-1 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3">
            <div class="flex justify-center items-center">
                <img src={charterDP} class="h-36 " fill="currentColor" alt="Charter Adopter"/>                                                                                
            </div>
            <div class="flex justify-center items-center">         
            <img src={nrlaDP} class="h-36 w-auto" fill="currentColor" alt="NRLA Member"/>                                                                         
            </div>
            <div  class="flex justify-center items-center">         
            <img src={twtaDP} class="h-36 "  fill="currentColor" alt="TWT Adopter" />                                                                         
            </div>

        </div>
    </div>
</section>

    </div>
    </div>
  );
};

export default WhatWeDo;
