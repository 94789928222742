export const careersContent = {
    title: "Careers",
    heading: "Working At Alliance",
    TextBlockA: "We really value our staff team – they are the reason Alliance Living provides excellent services! The staff at Alliance Living embrace and live our values on a day to day basis.",
    TextBlockB: "We are developing our dynamic team to support vulnerable adults at risk of homelessness and to provide for the accommodating service. Delivering Birmingham City Councils vision and its commitment to being a City of Sanctuary. our tenants require support with benefits, mental health services, substance misuse, social care and other specialist services. Our aim is to promote independent living skills such as budgeting and looking after their home and life skills.",
    TextBlockC: "To view our current vacancies and to apply please visit us at",
    TextBlockCLinkText: "Indeed.co.uk",
    TextBlockCLink: "#",
    TextBlockD: "Alternatively you can send us your CV at ",
    TextBlockDemail: "careers@allianceliving.org",


    TextBlockE: "If you are looking for volunteering opportunities within Alliance Living, send an email to:",
    TextBlockEemail: "volunteers@allianceliving.org"
}